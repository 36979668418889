<template>
    <base-card>
        <v-card-title class="title-post">
            <span> {{ title }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <div
                v-if="getImage"
                class="d-flex justify-center"
            >
                <img
                    :src="getImage"
                    height="200px"
                >
            </div>
            <div>
                <span
                    class="ql-editor"
                    v-html="post.attributes.body"
                />
            </div>
        </v-card-text>

        <nextPostBtn
            :url-next-post="nextPost"
            @changed:post="updateIndexShowPost"
        />
    </base-card>

    <!--    <div> {{ post && post.attributes && post.attributes.body }} </div>-->
</template>

<script>

import { mapGetters } from 'vuex'
import postMixin from '@/mixins/postMixin'
import nextPostBtn from '@/components/posts/nextPostBtn'

/** For Open and close the overlay fullscreen video */
window.openNav = function (url, poster) {
    const parentNode = document.getElementById("container-video-display");

    parentNode.innerHTML = `<video
                id="video-display"
                controls
                autobuffer
                poster="${poster}"
            >
               <source
                src="${url}"
                type="video/mp4"
            >
                Su navegador no soporta Videos HTML
            </video>`;

    document.getElementById("myNav").style.width = "100%";
}

window.closeNav = function () {
    document.getElementById("myNav").style.width = "0%";

    setTimeout(() => {
        const iframe = document.getElementById("video-display");
        iframe.parentNode.removeChild(iframe);
    }, 500);
}

export default {
    name: 'PostView',
    components: { nextPostBtn },
    mixins: [postMixin],
    data: () => ({}),
    computed: {
        title() {
            const attributes = this.post.attributes
            return attributes.title2 ?? attributes.title
        },

        getImage() {
            if (this.post.attributes.image2) {
                return process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=posts/images/' + this.post.attributes.image2
            }

            return ''
        }
    },
    mounted() {
        // window.open = function () { alert('ok') };
    },
    methods: {}

}
</script>

<style lang="css" scoped>
/*@import "~vue2-editor/dist/vue2-editor.css";*/

/* Import the Quill styles you want */
/*@import '~quill/dist/quill.core.css';*/
/*@import '~quill/dist/quill.bubble.css';*/

/*@import '~quill/dist/quill.snow.css';*/
</style>
